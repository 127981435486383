import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth';
import noop from 'lodash/noop';
import {auth} from '../database';
import {getUser} from '../services/users/get-user';
import isEmpty from 'lodash/isEmpty';

const INITIALIZE = 'INITIALIZE';
const UPDATE_USER = 'UPDATE_USER';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const {isAuthenticated, user} = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  if (action.type === UPDATE_USER) {
    const {user} = action.payload;
    return {
      ...state,
      user: {...state.user, ...user},
    };
  }

  return state;
};

const AuthContext = createContext(null);

function AuthProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    onAuthStateChanged(auth, async authUser => {
      if (!authUser?.uid) {
        dispatch({
          type: INITIALIZE,
          payload: {isAuthenticated: false, user: null},
        });
      }
      const user = await getUser(authUser?.uid);
      if (isEmpty(user)) {
        dispatch({
          type: INITIALIZE,
          payload: {isAuthenticated: false, user: null},
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {isAuthenticated: true, user},
        });
      }
    });
  }, []);

  const signIn = useCallback(
    (email, password) => signInWithEmailAndPassword(auth, email, password),
    [],
  );

  const onSignOut = useCallback(async () => {
    signOut(auth).catch(noop);
  }, []);
  const resetPassword = async email => sendPasswordResetEmail(auth, email);

  const onUpdateUserSuccess = useCallback(
    user => {
      dispatch({
        type: UPDATE_USER,
        payload: {
          user: {
            ...state.user,
            ...user,
          },
        },
      });
    },
    [state.user],
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: state.user,
        signIn,
        signOut: onSignOut,
        resetPassword,
        onUpdateUserSuccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context)
    throw new Error('AuthContext must be placed within AuthProvider');

  return context;
};

export {AuthContext, AuthProvider, useAuth};
