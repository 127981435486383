import React, {useMemo, useState} from 'react';
import styled from '@emotion/styled';
import {Outlet} from 'react-router-dom';

import {Box, CssBaseline} from '@mui/material';

import GlobalStyle from '../components/GlobalStyle';
import Navbar from '../components/navbar/Navbar';
import dashboardItems from '../components/sidebar/dashboardItems';
import Sidebar from '../components/sidebar/Sidebar';
import {useAuth} from '../contexts/FirebaseAuthContext';
import isEmpty from 'lodash/isEmpty';
import {includes} from 'lodash';

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
`;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled(Box)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const PrivateLayout = ({children}) => {
  const {user} = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const filteredRoutes = useMemo(
    () =>
      dashboardItems.map(item => {
        return {
          ...item,
          pages: item.pages.filter(item =>
            isEmpty(item.roles) ? true : includes(item.roles, user.role),
          ),
        };
      }),
    [user.role],
  );

  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      <Root>
        <Drawer>
          <Box sx={{display: {xs: 'block', lg: 'none'}}}>
            <Sidebar
              PaperProps={{style: {width: drawerWidth}}}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={filteredRoutes}
            />
          </Box>
          <Box sx={{display: {xs: 'none', md: 'block'}}}>
            <Sidebar
              PaperProps={{style: {width: drawerWidth}}}
              items={filteredRoutes}
            />
          </Box>
        </Drawer>
        <AppContent sx={{pl: {md: `${drawerWidth}px`}}}>
          <Navbar onDrawerToggle={handleDrawerToggle} />
          <main>
            <Box>
              {children}
              <Outlet />
            </Box>
          </main>
        </AppContent>
      </Root>
    </>
  );
};

export default PrivateLayout;
