import React from 'react';

import SidebarNavList from './SidebarNavList';

const SidebarNavSection = props => {
  const {
    title,
    pages,
    className,
    component: Component = 'nav',
    onClose,
    ...rest
  } = props;

  return (
    <Component {...rest}>
      <SidebarNavList pages={pages} depth={0} onClose={onClose} />
    </Component>
  );
};

export default SidebarNavSection;
