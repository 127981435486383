import React from 'react';
import styled from '@emotion/styled';

import {Avatar, Paper, Typography} from '@mui/material';

import ResetPasswordComponent from '../../components/auth/ResetPassword';

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)};

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 150px;
  height: 150px;
  margin: auto;
  border-radius: 0;
  margin-bottom: 40px;
`;

function ResetPassword() {
  return (
    <>
      <Wrapper>
        <BigAvatar alt="" src="/static/img/avatars/logo.png" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your email to reset your password
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </>
  );
}

export default ResetPassword;
