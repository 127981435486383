import React from 'react';
import styled from '@emotion/styled';

import {Avatar, Paper, Typography} from '@mui/material';

import SignInComponent from '../../components/auth/SignIn';

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)};

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 150px;
  height: 150px;
  margin: auto;
  border-radius: 0;
  margin-bottom: 40px;
`;

function SignIn() {
  return (
    <>
      <Wrapper>
        <BigAvatar alt="" src="/static/img/avatars/logo.png" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Welcome to Bayou Bud!
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <SignInComponent />
      </Wrapper>
    </>
  );
}

export default SignIn;
