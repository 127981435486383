import React from 'react';
import {Navigate} from 'react-router-dom';
import Loader from '../Loader';
import {useAuth} from '../../contexts/FirebaseAuthContext';

function PublicRouteGuard({children}) {
  const {isAuthenticated, isInitialized} = useAuth();

  if (!isInitialized) {
    return <Loader />;
  }
  if (isInitialized && isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}

export default PublicRouteGuard;
