import React, {useCallback} from 'react';
import styled from '@emotion/styled';
import {Power} from 'react-feather';
import {useNavigate} from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import {useAuth} from '../../contexts/FirebaseAuthContext';

const StyledIconButton = styled(IconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const {signOut} = useAuth();

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const onProfile = useCallback(async () => {
    closeMenu();
    navigate('/profile');
  }, [navigate]);

  return (
    <>
      <Tooltip title="Account">
        <StyledIconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </StyledIconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={onProfile}>Profile</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </>
  );
}

export default NavbarUserDropdown;
