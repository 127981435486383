import React from 'react';
import styled from '@emotion/styled';

import {Avatar, Badge, Grid, Typography} from '@mui/material';
import {useAuth} from '../../contexts/FirebaseAuthContext';

const Footer = styled.div`
  background-color: ${props =>
    props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)}
    ${props => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
`;

const FooterBadge = styled(Badge)`
  margin-right: ${props => props.theme.spacing(1)};

  span {
    background-color: ${props => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${props => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({...rest}) => {
  const {user} = useAuth();

  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <Avatar alt={user.displayName} src={user.avatar} />
          </FooterBadge>
        </Grid>
        <Grid item>
          <FooterText variant="body2">{user.email}</FooterText>
          <FooterText variant="body2">
            {user.firstName} {user.lastName}
          </FooterText>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
