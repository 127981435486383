import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import {Formik} from 'formik';

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from '@mui/material';
import spacing from '@mui/system/spacing';
import {useAuth} from '../../contexts/FirebaseAuthContext';

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);
function SignIn() {
  const {signIn} = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
        try {
          setSubmitting(true);
          await signIn(values.email, values.password);
        } catch (error) {
          const message = error.message || 'Something went wrong';

          setStatus({success: false});
          setErrors({submit: message});
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              sx={{mb: 2}}
            >
              Sign in
            </Button>
            <Button
              component={Link}
              to="/auth/reset-password"
              fullWidth
              color="primary"
              disabled={isSubmitting}
            >
              Forgot password
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}

export default SignIn;
