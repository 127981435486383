import React from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import {Formik} from 'formik';

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from '@mui/material';
import {spacing} from '@mui/system';
import {useAuth} from '../../contexts/FirebaseAuthContext';
import useSnackBar from '../SnackBar';
import {Link} from 'react-router-dom';

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const {notifySuccess} = useSnackBar();
  const {resetPassword} = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
      })}
      onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
        try {
          resetPassword(values.email);
          notifySuccess('Reset password email send successfully');
        } catch (error) {
          const message = error.message || 'Something went wrong';

          setStatus({success: false});
          setErrors({submit: message});
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{mb: 2}}
          >
            Reset password
          </Button>
          <Button
            component={Link}
            to="/auth/sign-in"
            fullWidth
            color="primary"
            disabled={isSubmitting}
          >
            Sign In
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
