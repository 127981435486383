import {initializeApp} from 'firebase/app';
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore';
import {connectAuthEmulator, getAuth} from 'firebase/auth';
import {connectStorageEmulator, getStorage} from 'firebase/storage';
import {getRemoteConfig} from 'firebase/remote-config';

import {CONFIG, firebaseConfig} from '../config';

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 5000;

if (CONFIG.isEmulator) {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099/');
  connectStorageEmulator(storage, 'localhost', 9199);
}

const COLLECTIONS = {
  FAXES: 'faxes',
  FAXES_COUNTS: 'faxes-counts',
  PHARMACIES: 'pharmacies',
  USERS: 'users',
  REPORTS: 'reports',
  EXPERITY_CRON_REPORTS: 'experity-cron-report',
  CONFIG: 'config',
};

const STORAGE_KEYS = {
  FAXES: 'faxes',
  SIGNATURES: 'signatures',
  WORK_EXCUSES: 'work-excuse',
};

export {db, auth, storage, remoteConfig, COLLECTIONS, STORAGE_KEYS};
