import {StrictMode} from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import reportWebVitals from './utils/reportWebVitals';

import {ThemeProvider} from './contexts/ThemeContext';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import 'instantsearch.css/themes/satellite.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      retry: 1,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
