import {useSnackbar as useBaseSnackBar} from 'notistack';
import {useCallback} from 'react';

export const useSnackBar = () => {
  const {enqueueSnackbar, closeSnackbar} = useBaseSnackBar();

  const notify = useCallback(
    (message, variant) => {
      const key = enqueueSnackbar(message, {
        variant,
        onClick: () => {
          closeSnackbar(key);
        },
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
    [closeSnackbar, enqueueSnackbar],
  );

  const notifySuccess = useCallback(
    message => {
      notify(message, 'success');
    },
    [notify],
  );

  const notifyError = useCallback(
    message => {
      notify(message, 'error');
    },
    [notify],
  );

  return {
    notifySuccess,
    notifyError,
  };
};

export default useSnackBar;
