import {doc, getDoc} from 'firebase/firestore';
import {COLLECTIONS, db} from '../../database';

export const getUser = async id => {
  try {
    const docRef = doc(db, COLLECTIONS.USERS, id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      return {id: snapshot.id, ...snapshot.data()};
    }
    return {};
  } catch (e) {
    return {};
  }
};
