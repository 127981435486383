import React from 'react';

import async from './components/Async';
import AuthLayout from './layouts/AuthLayout';
import DashboardLayout from './layouts/PrivateLayout';

import PrivateRouteGuard from './components/guards/PrivateRouteGuard';
import ResetPassword from './pages/auth/ResetPassword';

import SignIn from './pages/auth/SignIn';
import Page404 from './pages/auth/Page404';
import PublicRouteGuard from './components/guards/PublicRouteGuard';

const AppointmentsPage = async(() => import('./pages/pages/appointments'));
const PharmaciesPage = async(() => import('./pages/pages/pharmacies'));
const ProfilePage = async(() => import('./pages/pages/profile'));
const UsersPage = async(() => import('./pages/pages/users'));
const WorkExcusePage = async(() => import('./pages/pages/work-excuse'));
const FaxReportsPage = async(() => import('./pages/pages/fax-reports'));
const ClinicAnalyticsPage = async(
  () => import('./pages/pages/clinic-analytics'),
);

const routes = [
  {
    path: '/',
    element: (
      <PrivateRouteGuard>
        <DashboardLayout />
      </PrivateRouteGuard>
    ),
    children: [
      {
        path: '',
        element: <AppointmentsPage />,
      },
      {
        path: 'profile',
        element: <ProfilePage />,
      },
    ],
  },
  {
    path: 'appointments',
    element: (
      <PrivateRouteGuard>
        <DashboardLayout />
      </PrivateRouteGuard>
    ),
    children: [
      {
        path: '',
        element: <AppointmentsPage />,
      },
    ],
  },
  {
    path: 'pharmacies',
    element: (
      <PrivateRouteGuard>
        <DashboardLayout />
      </PrivateRouteGuard>
    ),
    children: [
      {
        path: '',
        element: <PharmaciesPage />,
      },
    ],
  },
  {
    path: 'users',
    element: (
      <PrivateRouteGuard>
        <DashboardLayout />
      </PrivateRouteGuard>
    ),
    children: [
      {
        path: '',
        element: <UsersPage />,
      },
    ],
  },
  {
    path: 'work-excuse',
    element: (
      <PrivateRouteGuard>
        <DashboardLayout />
      </PrivateRouteGuard>
    ),
    children: [
      {
        path: '',
        element: <WorkExcusePage />,
      },
    ],
  },
  {
    path: 'fax-reports',
    element: (
      <PrivateRouteGuard>
        <DashboardLayout />
      </PrivateRouteGuard>
    ),
    children: [
      {
        path: '',
        element: <FaxReportsPage />,
      },
    ],
  },
  {
    path: 'clinic-analytics',
    element: (
      <PrivateRouteGuard>
        <DashboardLayout />
      </PrivateRouteGuard>
    ),
    children: [
      {
        path: '',
        element: <ClinicAnalyticsPage />,
      },
    ],
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in',
        element: (
          <PublicRouteGuard>
            <SignIn />
          </PublicRouteGuard>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <PublicRouteGuard>
            <ResetPassword />
          </PublicRouteGuard>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
