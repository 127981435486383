export const firebaseConfig = {
  apiKey: 'AIzaSyCioSfSxN4rnj1cX32GCth_ht0bfFbIbZQ',
  authDomain: 'bayoubud-clinic.firebaseapp.com',
  projectId: 'bayoubud-clinic',
  storageBucket: 'bayoubud-clinic.appspot.com',
  messagingSenderId: '1056491658043',
  appId: '1:1056491658043:web:92541edb7bf44b46b9751e',
  measurementId: 'G-E61M7PLXMD',
};

const isEmulator = false;
export const CONFIG = {
  isEmulator,
  API_URL: isEmulator
    ? 'http://127.0.0.1:5001/bayoubud-clinic/us-central1/api'
    : 'https://api-gmw3xho3sa-uc.a.run.app',
};

export const AlgoliaConfig = {
  AlgoliaAppID: 'KRRCGUVGXF',
  AlgoliaSearchApiKey: '9a50fd3fbdcc220c2c48137d9c6ac694',
  FaxesIndex: 'faxes',
};
