import {Clipboard} from 'react-feather';
import {APP_ROLES} from '../../utils/constants';

const clinicSection = [
  {
    href: '/appointments',
    icon: Clipboard,
    title: 'Appointments',
    roles: [],
  },
  {
    href: '/pharmacies',
    icon: Clipboard,
    title: 'Pharmacies',
    roles: [],
  },
  {
    href: '/profile',
    icon: Clipboard,
    title: 'Profile',
    roles: [],
  },
  {
    href: '/work-excuse',
    icon: Clipboard,
    title: 'Work Excuse',
    roles: [],
  },
  {
    href: '/fax-reports',
    icon: Clipboard,
    title: 'Fax Reports',
    roles: [],
  },
  {
    href: '/clinic-analytics',
    icon: Clipboard,
    title: 'Clinic Analytics',
    roles: [APP_ROLES.ADMIN],
  },
  {
    href: '/users',
    icon: Clipboard,
    title: 'Users',
    roles: [APP_ROLES.ADMIN],
  },
];

const navItems = [
  {
    title: 'User',
    pages: clinicSection,
  },
];

export default navItems;
