import React from 'react';
import {useRoutes} from 'react-router-dom';
import {CacheProvider} from '@emotion/react';

import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {SnackbarProvider} from 'notistack';
import createTheme from './theme';
import routes from './routes';

import useTheme from './hooks/useTheme';
import createEmotionCache from './utils/createEmotionCache';
import {AuthProvider} from './contexts/FirebaseAuthContext';

const clientSideEmotionCache = createEmotionCache();

function App({emotionCache = clientSideEmotionCache}) {
  const content = useRoutes(routes);
  const {theme} = useTheme();
  return (
    <CacheProvider value={emotionCache}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiThemeProvider theme={createTheme(theme)}>
          <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
            <AuthProvider>{content}</AuthProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
}

export default App;
