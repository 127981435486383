import React from 'react';
import {Navigate} from 'react-router-dom';
import Loader from '../Loader';
import {useAuth} from '../../contexts/FirebaseAuthContext';

function PrivateRouteGuard({children}) {
  const {isAuthenticated, isInitialized} = useAuth();

  if (!isInitialized) {
    return <Loader />;
  }

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  return <>{children}</>;
}

export default PrivateRouteGuard;
