import React from 'react';
import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

import {Avatar, Drawer as MuiDrawer} from '@mui/material';

import Footer from './SidebarFooter';
import SidebarNav from './SidebarNav';
import {useTheme} from '@mui/material/styles';

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: auto;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  object-fit: contain;
`;

const Sidebar = ({items, showFooter = true, ...rest}) => {
  const theme = useTheme();
  return (
    <Drawer variant="permanent" {...rest}>
      <Link to="/" style={{backgroundColor: theme.sidebar.background}}>
        <BigAvatar alt="" src="/static/img/avatars/logo.png" />
      </Link>
      <SidebarNav items={items} onClose={rest.onClose} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
